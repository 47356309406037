<template>
  <div class="container">
    <!-- 表头 -->
    <div class="topup-hread">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- :to="{ path: '/Topupoverview' }"  跳转-->
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
        <el-breadcrumb-item>活动课程管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-dialog :visible.sync="aaa" width="45%" center>
      <div v-if="aaa">
        <div class="ontents">
          <div class="topup-title">课程列表</div>
        </div>
        <el-button class="btnadd" @click="tianjiakc">添加课程</el-button>
        <!-- <el-button class="btnadd" @click="addyn = !addyn">添加课程</el-button> -->
        <!-- 添加课程 -->
        <div class="contentList">
          <div class="paging">
            <el-table ref="multipleTable" :data="options" tooltip-effect="dark" style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection"> </el-table-column>
              <el-table-column prop="id" label="id"> </el-table-column>
              <el-table-column prop="title" label="姓名"> </el-table-column>
              <el-table-column prop="create_time" label="创建时间">
              </el-table-column>
            </el-table>
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
            <div style="">
              <el-button type="primary" class="xinzeng" @click="insertAcativityCourse">确定</el-button>
              <el-button class="xinzeng" @click="aaa = !aaa">取消</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-button type="warning" @click="addMec2Activity">添加机构参加活动</el-button>
    <!-- 查看 -->
    <div>
      <div class="topup-title">已参加该活动机构</div>
      <el-table :data="tableListData" border style="width: 100%">
        <el-table-column type="index" align="center" width="100" label="序号">
        </el-table-column>
        <el-table-column prop="id" label="id" align="center" width="100">
        </el-table-column>

        <el-table-column prop="mechanism_name" align="center" width="150" label="机构名">
        </el-table-column>
        <el-table-column prop="mechanism_addr" align="center" label="地址">
        </el-table-column>
        <el-table-column prop="contacts" align="center" label="联系人">
        </el-table-column>
        <el-table-column prop="contact_telephone" align="center" label="联系方式">
        </el-table-column>
        <el-table-column align="center" label="修改机构信息">
          <template slot-scope="scope">
            <el-button @click="xiugaijigouxxbut(scope.row)" type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="课程管理">
          <template slot-scope="scope">
            <el-button @click="aaachange(scope.row)" type="text" size="small">添加课程</el-button>
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看课程</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="LoweringMechanism(scope.row)" type="text" size="small">下架机构</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 弹出框 -->
    <el-dialog title="活动课程" :visible.sync="centerDialogVisible" width="45%" center>
      <el-table ref="multipleTable" :data="courseList" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection"> </el-table-column>
        <el-table-column align="center" type="index" label="序号">
        </el-table-column>
        <el-table-column align="center" prop="id" label="id"> </el-table-column>
        <el-table-column align="center" prop="title" label="课程名">
        </el-table-column>
        <el-table-column align="center" prop="apply_age " label="适用年龄">
        </el-table-column>
        <el-table-column align="center" prop="length_of_lesson " label="课时长">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="remote(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加课程 -->
    <!-- <el-dialog title="添加课程" :visible.sync="addyn">
      <el-form :model="addcourse">
        <el-form-item label="课程标题" :label-width="formLabelWidth">
          <el-input v-model="addcourse.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="课节数" :label-width="formLabelWidth">
          <el-input
            v-model="addcourse.course_num"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="原价" :label-width="formLabelWidth">
          <el-input v-model="addcourse.amout" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="折扣价" :label-width="formLabelWidth">
          <el-input
            v-model="addcourse.discount_amout"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="每节课时长" :label-width="formLabelWidth">
          <el-input
            v-model="addcourse.length_of_lesson"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="适用年龄（n-m）" :label-width="formLabelWidth">
          <el-input v-model="addcourse.apply_age" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addyn = false">取 消</el-button>
        <el-button type="primary" @click="addkechengtz">确 定</el-button>
      </div>
    </el-dialog> -->

    
    <el-dialog class="dialog" title="添加机构" :visible.sync="addMecVisable" :center="true" width="1000px">
      <el-button style="float:right" @click="addInstitution">新增机构</el-button>
      <!-- 搜索 -->
      <div class="position">
        <div class="PERInformation-from">
          <label>
            <span>机构名称</span>
            <el-input type="text" v-model="sub_mechanism_name" name="sub_mechanism_name" id="name" />
          </label>
          <label>
            <span>机构电话</span>
            <el-input type="text" v-model="sub_mechanism_telephone" name="sub_mechanism_telephone" id="tell" />
          </label>
          <label>
            <el-button @click="searchMec">查询</el-button>
          </label>
        </div>
      </div>
      <!-- 添加机构列表 -->
      <div>
        <div class="contentList">
          <div class="paging">
            <el-table ref="multipleTable" :data="mecList" tooltip-effect="dark" style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection"></el-table-column>
              <el-table-column prop="id" label="id" width="100"></el-table-column>
              <el-table-column prop="mechanism_name" label="姓名"></el-table-column>
              <el-table-column prop="mechanism_addr" width="200" label="地址" show-overflow-tooltip></el-table-column>
              <el-table-column prop="contacts_title" align="center" label="联系人"></el-table-column>
              <el-table-column prop="contact_telephone" align="center" label="联系人电话"></el-table-column>
            </el-table>
            
            <!--分页-->
            <el-pagination background  @current-change="handleCurrentChangeMec"
              :current-page.sync="currentPage2" :page-size="10" layout="prev, pager, next, jumper" :total="mecTotal">
            </el-pagination>
            <el-button @click="addjg" type="primary" class="xinzeng">确定</el-button>
            <el-button @click="addMecVisable = !addMecVisable" class="xinzeng">取消</el-button>
          </div>
        </div>
      </div>
    </el-dialog>


    <el-dialog title="修改机构信息" :visible.sync="ModificationOrganization">
      <el-form :model="ModificationOrganizationList">
        <el-form-item label="机构名称" label-width="120px">
          <el-input v-model="jgid.mechanism_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="学区环境1" label-width="120px">
          <div class="my-upload">
            <avatar-uploader @getImgUrl="Uploadcourintroductioncover" :clear="if_clear"
              :url='ModificationOrganizationList.xuequimg'></avatar-uploader>
          </div>
        </el-form-item>
        <el-form-item label="学区环境2" label-width="120px">
          <div class="my-upload">
            <avatar-uploader @getImgUrl="Uploadcourintroductioncover2" :clear="if_clear"
              :url='ModificationOrganizationList.xuequimg2'></avatar-uploader>
          </div>
        </el-form-item>
        <el-form-item label="学员风采1" label-width="120px">
          <div class="my-upload">
            <avatar-uploader @getImgUrl="Uploadcourintroductioncover3" :clear="if_clear"
              :url='ModificationOrganizationList.xueyuanimg'></avatar-uploader>
          </div>
        </el-form-item>
        <el-form-item label="学区风采2" label-width="120px">
          <div class="my-upload">
            <avatar-uploader @getImgUrl="Uploadcourintroductioncover4" :clear="if_clear"
              :url='ModificationOrganizationList.xueyuanimg2'></avatar-uploader>
          </div>
        </el-form-item>
        <el-form-item label="机构二维码" label-width="120px">
          <div class="my-upload">
            <avatar-uploader @getImgUrl="Uploadcourintroductioncover5" :clear="if_clear"
              :url='ModificationOrganizationList.jgewm'></avatar-uploader>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ModificationOrganization = false">取 消</el-button>
        <el-button type="primary" @click="xgjgupdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Activityaction",
  data() {
    return {
      if_clear: true,
      formLabelWidth: "140px",
      addMecVisable: false,
      centerDialogVisible: false,
      id: {},
      arr: { num: 1, arrdata: [] },
      multipleSelection: [],
      options: [], //选机构列表
      courseList: [],
      tableListData: [],
      mechanism_id: {},
      pageSize: 10,
      currentPage: 1,
      total: 0,
      mecList:[],
      mecTotal:0,
      aaa:false,
      listCourse: [],
      addcourse: {},
      addyn: false,
      jgid: "",
      ModificationOrganization: false,
      ModificationOrganizationList: { name: "aa" },
      // 图片上传
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      sub_mechanism_name:'',
      sub_mechanism_telephone:''
    };
  },
  mounted() {
    this.getActivityInfo()
    this.getOldList();
  },
  methods: {
    getActivityInfo() {
      let url ="/user/businessActivityType/queryManagerListPage?id=" + this.$route.query.id;
      this.$axios.get(url).then((res) => {
        // console.log(res);
        this.id = res.data.data.rows[0]
      });
    },
    addInstitution() {
      this.$router.push({ name: "checkMechanism" });
    },
    searchMec() {
      console.log(this.sub_mechanism_name, this.sub_mechanism_telephone);
      if (this.sub_mechanism_name == "" && this.sub_mechanism_telephone == "") {
        this.getListjigou();
      } else {
        let params={
          type:"teach_paypal",
          mechanism_name:this.sub_mechanism_name || null,
           mechanism_telephone:this.sub_mechanism_telephone || null
        }
        this.$axios
          .get(
            "/user/mastermechanism/queryByMessage" ,{params}
          )
          .then((res) => {
            this.mecList = res.data.data.rows;
        this.mecTotal = res.data.data.total;
          });
      }
    },
    addMec2Activity(){
      this.addMecVisable = true
      this.getListjigou()
    },
    addjg() {
      console.log(this.id.entry_mechanism_ids);
      let a = "";
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (i > 0) {
          a += "," + this.multipleSelection[i].id;
        } else {
          a += this.multipleSelection[i].id;
        }
      }
      if (this.id.entry_mechanism_ids == "") {
        a = a;
      } else if (this.id.entry_mechanism_ids != "") {
        a = this.id.entry_mechanism_ids + "," + a;
      }
      console.log(a);
      let url = "/user/businessActivityType/update";
      let data = {
        id: this.$route.query.id,
        entry_mechanism_ids: a,
      };
      this.$axios.post(url, data).then((res) => {
        this.$message({ message: res.data.message })
        this.addMecVisable = false
      this.getOldList()
      });
    },
    handleCurrentChangeMec(v){
      this.currentPage=v
      this.getListjigou()
    },
    //查询机构列表
    getListjigou() {
      let url = "/user/mastermechanism/queryManagerListPage?status=2&type=teach_paypal&pageSize=10&currentPage=" +this.currentPage;
      this.$axios.get(url).then((res) => {
        // console.log(res);
        this.mecList = res.data.data.rows;
        this.mecTotal = res.data.data.total;
      });
    },
    //机构选择课程参加活动
    insertAcativityCourse() {
      if (this.multipleSelection.length == 0) {
        this.$message({ type: 'info', message: '请选择课程' })
        return
      }
      let data = {
        "master_set_price_ids": this.multipleSelection.reduce((pre, cur) => {
          pre.push(cur.id)
          return pre
        },[]).join(','),
        "price_list": this.multipleSelection.reduce((pre, cur) => {
          pre.push(cur.amout)
          return pre
        },[]).join(','),
        "mechanism_id": this.jigouid.id,
        "business_activity_type_id": this.$route.query.id,
        "start_time": this.id.start_time,
        "end_time": this.id.end_time,
        "type": this.id.type,
        "number_of_people": this.id.number_of_people,
        "each_time_percentage": this.id.each_time_percentage,
        "each_time_percentage_max": this.id.each_time_percentage_max
      }
      this.$axios({
        url: '/user/businessActivity/insertActivity',
        data,
        method: "post"
      }).then(res => {
        if (res.data.code == 0) {
           this.aaa = false
          this.$message({ type: 'success', message: '添加成功' })
        } else {
            this.aaa = false
          this.$message({ type: 'info', message: res.data.message })
        }
      })
    },
    tianjiakc() {
      // console.log(this.jigouid);
      this.$router.push({
        name: "mecDetails",
        query: { mecID:this.jigouid.id},
      });
    },
    xiugaijigouxxbut(row) {
      this.ModificationOrganization = !this.ModificationOrganization;
      console.log(row);
      this.jgid = row;
    },
    xgjgupdate() {
      let url = "/user/mastermechanism/update";
      let data = {
        id: this.jigouid.id,
        status: 2,
        students_pic:
          this.ModificationOrganizationList.xueyuanimg +
          "," +
          this.ModificationOrganizationList.xueyuanimg2,
        environment_pic:
          this.ModificationOrganizationList.xuequimg +
          "," +
          this.ModificationOrganizationList.xuequimg2,
        qrcode: this.ModificationOrganizationList.jgewm,
      };
      console.log(data, this.jgid);

      this.$axios.post(url, data).then((res) => {
        console.log(res);
      });
    },
    remote(row) {
      // console.log(row, this.$route.query.id);
      let url =
        "/user/businessActivity/deleteByActivityInfo";
      let data = {
        master_set_price_id: row.id,
        business_activity_type_id: this.$route.query.id,
      };
      this.$axios.post(url, data).then((res) => {
        this.$message({message:'删除成功'})
        this.centerDialogVisible = !this.centerDialogVisible;
      });
    },
    //下架机构
    LoweringMechanism(row) {
      let a = [...new Set(this.id.entry_mechanism_ids.split(","))];
      // console.log(a, row.id + "");
      let b = a.indexOf(row.id + "");
      if (b != -1) {
        a.splice(b, 1);
      } else {
        a = a;
      }
      a = a.toString();

      // console.log(b);
      // console.log(this.$route.query.id,row.id, a, "机构列表");
      let url = "/user/businessActivityType/update";
      let data = {
        id: this.$route.query.id,
        entry_mechanism_ids: a,
      };
      this.$axios.post(url, data).then((res) => {
        this.getOldList();
      });
    },
    xiugai(row) {
      // console.log(row);
      let url = "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        length_of_lesson: "45", //一节课时长
        apply_age: "4-15", //适用年龄
        // title: "天天体育", //课程标题
        course_num: "2", //课时数
      };
      this.$axios.post(url, data).then((res) => {
        // console.log(res);
      });
    },
    //已参加活动机构
    getOldList() {
      this.$axios
        .get(
          "/user/mastermechanism/queryMechanismActivityList?id=" +
          this.$route.query.id +
          "&type=teach_payal"
        )
        .then((res) => {
          // console.log(res);
          this.tableListData = res.data.data;
          if (this.tableListData[0] != null) {
            this.listCourse = this.tableListData[0].map.courseList;
          } else {
            this.listCourse = [];
          }
        });
    },
    //查询已参加机构课程
    handleClick(row) {
      // console.log(row);
      // this.courseList = row.map.courseList;
      this.$axios
        .get(
          "/user/masterSetPrice/queryListPageByActivityId?activity_id=" +
          this.$route.query.id +
          "&mechanism_id=" +
          row.id+'&status=2'
        )
        .then((res) => {
          // console.log(res);
          // console.log(row.mechanism_name + "课程");
          this.courseList = res.data.data;
        });
      this.centerDialogVisible = !this.centerDialogVisible;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    aaachange(row) {
      this.aaa = !this.aaa;
      // console.log(row);
      this.getListKc(row);
      this.jigouid = row;
    },
    getListKc(row) {
      let params = {
        status: 2,
        type: 'mechanism_offline',
        mechanism_id: row.id,
        pageSize: 10,
        currentPage: this.currentPage,
        categories: "体育运动"
      }
      this.$axios({
        url: '/user/masterSetPrice/queryByMessage',
        params,
        method: 'get'
      }).then(res => {
        this.options = res.data.data.rows;
        this.total = res.data.data.total;
      })
      // let url =
      //   "/user/masterSetPrice/queryByEs?status=1&type=mechanism_offline&pageSize=10&currentPage=" +
      //   this.currentPage +
      //   "&mechanism_id=" +
      //   aaa.id;
      // this.$axios.get(url).then((res) => {
      //   // console.log(res);
      //   this.options = res.data.data.rows;
      //   this.total = res.data.data.total;
      // });
    },

    // addkechengtz() {
    //   this.addyn = !this.addyn;
    //   location.reload();

    //   // console.log(this.jigou$route.query.id);
    //   let url =  "/user/masterSetPrice/insert";
    //   let data = {
    //     mechanism_id: this.jigou$route.query.id,
    //     type: "mechanism_offline",
    //     status: "2",
    //     title: this.addcourse.title, //标题
    //     course_num: this.addcourse.course_num, //课节数
    //     amout: this.addcourse.amout, //原价
    //     discount_amout: this.addcourse.discount_amout, //折扣价
    //     length_of_lesson: this.addcourse.length_of_lesson, //一节课时长
    //     apply_age: this.addcourse.apply_age, //适用年龄
    //   };
    //   // console.log(data);
    //   this.$axios.post(url, data).then();
    // },

    //分页函数
    handleSizeChange(val) {
      //  console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `);
      this.currentPage = val;
      this.getListKc(this.jigouid);
    },
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
          // console.log(_this.QiNiYunL, " _this.QiNiYunL _this.QiNiYunL");
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    beforeUploadGetKeys(file) {
      this.gettoken();
    },
    Uploadcourintroductioncover(file) {
      this.ModificationOrganizationList.xuequimg =
        file
    },
    Uploadcourintroductioncover2(file) {
      this.ModificationOrganizationList.xuequimg2 =
        file
    },
    Uploadcourintroductioncover3(file) {
      this.ModificationOrganizationList.xueyuanimg =
        file
    },
    Uploadcourintroductioncover4(file) {
      this.ModificationOrganizationList.xueyuanimg2 =
        file
    },
    Uploadcourintroductioncover5(file) {
      this.ModificationOrganizationList.jgewm =
        file
    },
  },
};
</script>

<style scoped>
.btnadd {
  position: relative;
  left: 473px;
  bottom: 20px;
}

.xinzeng {
  position: relative;
  left: 423px;
  top: 10px;
}

.topup-hread {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.topup-hread>>>.el-breadcrumb__inner {
  font-weight: bold;
  font-size: 20px;
}

.topup-title {
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: bold;
}

.blt {
  position: absolute;
  top: 200px;
  left: 30%;
}

/* 。。。 */
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}

/* 。。。 */
</style>